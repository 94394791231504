<template>
	<b-sidebar
		:title="`${tournament && tournament.name}`"
		width="40em"
		body-class="bg-white"
		backdrop
		right
		shadow
		v-model="isActive"
		@hidden="handleHidden"
		no-close-on-backdrop
	>
		<b-overlay :show="isLoading">
			<b-row class="m-2">
				<b-col cols="12" class="">
					<div class="d-flex align-items-center gap-x-2 mb-2">
						<span class="h4 mb-0 title-form">1. Fechas</span>
					</div>
					<b-row>
						<b-col>
							<b-form-group label="Inauguracion" label-class="font-weight-bold">
								<p class="mb-0">{{ tournament.start_date | myGlobalDayShort }}</p>
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-group label="Final" label-class="font-weight-bold">
								<p class="mb-0">{{ tournament.end_date | myGlobalDayShort }}</p>
							</b-form-group>
						</b-col>
					</b-row>
				</b-col>

				<b-col cols="12" class="">
					<div class="d-flex align-items-center gap-x-2 mb-2">
						<span class="h4 mb-0 title-form">2. Descripción</span>
					</div>
					<b-row>
						<b-col>
							<p class="mb-0">{{ tournament.description ? tournament.description : "---" }}</p>
						</b-col>
					</b-row>
				</b-col>

				<b-col cols="12" class="mt-2">
					<h4 class="mb-0 title-form">3. Etapas</h4>
					<template v-if="tournament.stages">
						<b-row>
							<b-col class="text-right" cols="5"><h6>Etapa</h6></b-col>
							<b-col cols="2"></b-col>
							<b-col cols="5">
								<h6>Cantidad de tipos</h6>
							</b-col>
						</b-row>
						<b-row>
							<b-col class="text-right" cols="5">
								<span class="font-weight-bold">Pre-clasificación</span>
							</b-col>
							<b-col cols="2"></b-col>
							<b-col class="text-left" cols="5">{{ filterStages(1).length }}</b-col>
						</b-row>
						<b-row>
							<b-col class="text-right" cols="5">
								<span class="font-weight-bold">Clasificación</span>
							</b-col>
							<b-col cols="2"></b-col>
							<b-col class="text-left" cols="5">{{ filterStages(2).length }}</b-col>
						</b-row>
						<b-row>
							<b-col class="text-right" cols="5">
								<span class="font-weight-bold">Semifinal</span>
							</b-col>
							<b-col cols="2"></b-col>
							<b-col class="text-left" cols="5">{{ filterStages(3).length }}</b-col>
						</b-row>
						<b-row>
							<b-col class="text-right" cols="5">
								<span class="font-weight-bold">Final</span>
							</b-col>
							<b-col cols="2"></b-col>
							<b-col class="text-left" cols="5">{{ filterStages(4).length }}</b-col>
						</b-row>
					</template>
				</b-col>

				<b-col cols="12" class="mt-2">
					<div class="d-flex align-items-center gap-x-2 mb-2">
						<span class="h4 mb-0 title-form">4. Coliseo(s)</span>
					</div>
					<div>
						<b-row class="mb-50">
							<b-col class="font-weight-bolder">Nombre</b-col>
							<b-col class="font-weight-bolder">Ciudad</b-col>
						</b-row>
						<b-row class="mt-25" v-for="(col, index) in tournament.coliseums" :key="index">
							<b-col>
								<b-badge :variant="col.main ? 'primary' : 'secondary'">
									{{ col.name }}
								</b-badge>
							</b-col>
							<b-col>
								{{ col.city }}
							</b-col>
						</b-row>
					</div>
				</b-col>

				<b-col cols="12" class="mt-2">
					<div class="d-flex align-items-center gap-x-2 mb-2">
						<span class="h4 mb-0 title-form">5. Organizadores</span>
					</div>
					<div>
						<b-row class="mb-50">
							<b-col class="font-weight-bolder">Nombre</b-col>
							<b-col class="font-weight-bolder">Telefonos</b-col>
						</b-row>
						<template v-if="tournament.organizers.length > 0">
							<b-row class="mt-25" v-for="(col, index) in tournament.organizers" :key="index">
								<b-col>
									{{ col.name }}
								</b-col>
								<b-col>
									{{ col.phone }}
								</b-col>
							</b-row>
						</template>
						<template v-else>
							<b-row class="mt-25">
								<b-col class="">Sin organizadores</b-col>
							</b-row>
						</template>
					</div>
				</b-col>

				<b-col cols="12" class="mt-2">
					<div class="d-flex align-items-center gap-x-2 mb-2">
						<span class="h4 mb-0 title-form">6. Premio total</span>
					</div>
					<b-input-group>
						<p class="mb-0">
						{{tournament.currency}}  {{ tournament.total_prize ? fixedMoney(tournament.total_prize) : "0.00" }}
						</p>
					</b-input-group>
				</b-col>

				<b-col cols="12" class="mt-2">
					<div class="d-flex align-items-center gap-x-2 mb-2">
						<span class="h4 mb-0 title-form">7. Comentario</span>
					</div>
					<b-input-group>
						<b-form-textarea
							v-if="tournament.comment"
							class="text-messages"
							id="textarea"
							v-model="tournament.comment"
							placeholder="Agregar comentario"
							rows="3"
							max-rows="6"
							disabled
						></b-form-textarea>
						<p class="mb-0" v-else>{{ "---" }}</p>
					</b-input-group>
				</b-col>

				<b-col cols="12" class="mt-2">
					<div class="d-flex align-items-center gap-x-2 mb-2">
						<span class="h4 mb-0 title-form">8. Flyers</span>
					</div>
					<div class="container-flyers" v-if="tournament.flyers.length > 0">
						<div     v-for="(image, index) in tournament.flyers" style="position: relative">
							<img
								@click="image.format !== 'pdf' ? viewSpecimen(tournament, index) : ''"
								class="flyer"
								:src="image.path"
								alt=""
								:key="index"
								:class="image.format !== 'pdf' ? 'cursor-pointer' : ''"
							/>
							<a
								v-b-tooltip.hover.top="'Visualizar'"
								v-if="image.format === 'pdf'"
								download
								target="_blank"
								:href="image.download"
								style="position: absolute; right: 1rem"
							>
								<i class="fa-solid fa-eye"></i>
							</a>
						</div>
					</div>
					<div v-else>---</div>
				</b-col>

			 <b-col cols="12" class="mt-2">
					<div class="d-flex align-items-center gap-x-2 mb-2">
						<span class="h4 mb-0 title-form">9. Avatar</span>
					</div>
					<InputImages readonly v-model="tournament.avatar" v-if="tournament.avatar[0]" />
					<span v-else>---</span>
				</b-col> 
			</b-row>
		</b-overlay>
		<template #footer>
			<div class="p-2 d-flex justify-content-end">
				<b-button v-b-tooltip.hover title="Editar torneo" variant="warning" @click="openModalDetails(info)">
					<feather-icon icon="Edit3Icon" size="20" />
				</b-button>
			</div>
		</template>

		<DetailsTournament
			:show="modalDetails.show"
			:tournament_id="modalDetails.tournament_id"
			:info="modalDetails.info"
			@closing="
				modalDetails.show = false
				modalDetails.tournament_id = null
				tournament_id && init(tournament_id)
			"
		/>
	</b-sidebar>
</template>

<script>
import tournamentService from "@/services/tournament/default.service"
import SelectableImages from "./SelectableImages.vue"
import coliseumService from "@/services/tournament/coliseum.service"
import contactService from "@/services/tournament/contacts.service"
import { Money } from "v-money"
import DetailsTournament from "./DetailsTournament.vue"
import { mapActions } from "vuex"
import InputImages from "@/components/commons/InputImages.vue"

export default {
	name: "ShowTournament",
	components: { SelectableImages, Money, DetailsTournament, InputImages },
	props: {
		show: Boolean,
		tournament_id: Number,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		tournament: {
			name: "",
			start_date: "",
			end_date: "",
			stages: [],
			coliseums: [],
			coliseumMain: null,
			organizers: [],
			total_prize: "",
			comment: "",
			flyers: [],
			limits: { min: undefined, max: undefined },
			avatar: [],
			currency:null
		},
		editingName: false,
		newName: null,
		arrayColiseums: [],
		arrayOrganizers: [],
		money: {
			decimal: ".",
			thousands: ",",
			prefix: "S./ ",
			suffix: "",
			precision: 2,
			masked: false,
		},
		modalDetails: {
			show: false,
			tournament_id: null,
			info: null,
		},
	}),

	async created() {
		await this.initOpts()

		if (this.tournament_id) {
			await this.initOpts()
			await this.init(this.tournament_id)
		}
	},
	methods: {
		...mapActions("header_book", [
			"setImagesVideoteca",
			"toggleModal",
			"setIndexImage",
			"setTextImage",
			"setUploadImages",
			"deleteUploadFile",
			"toggleBgUpload",
			"initToggleUpload",
			"toggleModalGallery",
			"toggleUploadGallery",
			"setVideoteca",
			"setImages",
		]),
		handleHidden() {
			this.isActive = false
			this.editingName = false
			this.newName = null
			this.tournament = {
				name: "",
				start_date: "",
				end_date: "",
				stages: [],
				coliseums: [],
				coliseumMain: null,
				organizers: [],
				total_prize: "",
				comment: "",
				flyers: [],
				limits: { min: undefined, max: undefined },
				avatar: [],
			}
			this.$emit("closing")
		},
		async init(id) {
			this.setVideoteca(false)
			this.isLoading = true
			const { data } = await tournamentService.getTournamentById(id)
 			const updatedFlyers = (data.flyers || []).map((it) => {
				let image = ""  

				// Verificamos si el path tiene la extensión .pdf
				if (/\bpdf\b/i.test(it.path)) {
					// Si es un PDF, asignamos la URL de una imagen representativa de PDF
					image = "https://imprenta.wiki/wp-content/uploads/2017/10/PDF-300x294.jpg"
				}

				// Retornamos un nuevo objeto con la propiedad image agregada
				return {
					...it,
					main: it.main == 1 ? true : false,
					delete: false,
					path: image ? image : it.path,
					download: it.path,
					format: image ? "pdf" : "image",
				}
			})

			console.log(updatedFlyers)
			let fixedStages = []
			if (data.stages) fixedStages = data.stages.map((s) => ({ ...s.data, id: s.id }))
			this.tournament = {
				name: data.name,
				start_date: data.start_date,
				end_date: data.end_date,
				stages: fixedStages,
				coliseums: !!data.coliseums
					? data.coliseums.map((c) => ({
							name: c.name,
							value: c.id,
							main: c.main,
							canDelete: c.disabled,
							city: c.city,
					  }))
					: [],
				coliseumMain: data.coliseums.find((c) => c.main == 1).id,
				organizers: !!data.contacts
					? data.contacts.map((o) => ({ name: o.name, value: o.id, phone: o.phone }))
					: [],
				total_prize: data.total_prize,
				comment: data.comment,

				flyers: updatedFlyers,
				limits: data.dates || { min: null, max: null },
				avatar: [!!data.avatar_name ? { url: data.avatar_name } : undefined],
				description: data.description,
				currency:data.currency.name
			}
			this.isLoading = false

			this.setImages(
				(data.flyers || []).map((item) => {
					return {
						...item,
						id: 1,
						id_specimens: 1,
						plate: "",
						name: data.name,
						preload: item.path,
						type: "image",
						url: item.path,
					}
				})
			)
		},
		async initOpts() {
			this.isLoading = true
			const { data: colliseums } = await coliseumService.getColiseumsToSelect({
				campo: null,
				pageIn: 1,
				perPageIn: 1000,
				status: 0,
			})
			this.arrayColiseums = colliseums.data.map((it) => ({ value: it.id_coliseum, name: it.name }))
			const { data: organizers } = await contactService.getContactsToStatus({
				campo: null,
				pageIn: 1,
				perPageIn: 10000,
				status: 0,
			})
			this.arrayOrganizers = organizers.data.map((it) => ({ value: it.id, name: it.name }))
			this.isLoading = false
		},
		coliseumChange(e) {
			this.tournament.coliseumMain = e
		},
		handleColiseumSelect(e) {
			let repeated = this.tournament.coliseums.some((c) => c.value == e.value)
			if (repeated) return
			this.tournament.coliseums.push(e)
		},
		handleColiseumDelete(e) {
			if (e.canDelete) {
				this.showToast(
					"warning",
					"top-right",
					"Torneos",
					"AlertIcon",
					"No se puede eliminar un coliseo que tiene fechas asignadas."
				)
				return
			}
			let wasMain = e.main == 1
			let idx = this.tournament.coliseums.findIndex((opt) => opt.value == e.value)
			this.tournament.coliseums.splice(idx, 1)
			if (wasMain && this.tournament.coliseums.length > 0) {
				// reassign main
				this.tournament.coliseumMain = this.tournament.coliseums[0].id
				this.tournament.coliseums[0].main = 1
			}
		},
		async updateName() {
			this.isLoading = true
			await tournamentService.updateTournamentName({ tournament_id: this.info.id, name: this.newName })
			this.editingName = false
			this.newName = null
			this.init(this.info.id)
			this.$emit("refresh")
			this.isLoading = false
		},
		handleOrganizerSelect(e) {
			let repeated = this.tournament.organizers.some((o) => o.value == e.value)
			if (repeated) return
			this.tournament.organizers.push(e)
		},
		handleOrganizerDelete(e) {
			let idx = this.tournament.organizers.findIndex((o) => o.value == e.value)
			this.tournament.organizers.splice(idx, 1)
		},
		filterStages(type) {
			// removes deleted rows and filter by stage
			return this.tournament.stages.filter((s) => !s.delete).filter((s) => s.stage_id == type)
		},
		openModalDetails(item) {
			this.modalDetails.show = !this.modalDetails.show
			this.modalDetails.tournament_id = item.id
			this.modalDetails.info = item
		},
		viewSpecimen(tournament, index) {
			console.log(index)
			this.toggleModal()
			this.setTextImage({
				title: tournament.name,
				url: null,
			})
			console.log(tournament)
			this.setIndexImage(index)
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
			}
		},
		async tournament_id() {
			if (this.tournament_id) {
				await this.init(this.tournament_id)
			}
		},
	},
	destroyed() {
		this.setVideoteca(false)
		this.setImagesVideoteca([])
		this.items = []
	},
}
</script>

<style scoped lang="scss">
.title-form {
	font-family: "Rubik" !important;
	font-size: 20px;
}

.container-flyers {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;
	@media screen and (min-width: 600px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
	.flyer {
		width: 100%;
		height: 150px;
		border-radius: 12px;
		object-fit: cover;
	}
}

.text-messages {
	background: transparent;
	overflow: hidden;
	// ov
	border: none;
}
</style>
