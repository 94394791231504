<template>
	<div
		:style="{ backgroundColor: bgColor.bg, overflow: 'hidden' }"
		class="text-dark cursor-pointer container-item-canva"
	>
		<div
			class="position-relative d-flex justify-content-between align-items-center pt-1 pl-1 pr-1"
			@click="compact = !compact"
		>
			<ViewImage
				style="margin-right: 0.5rem"
				:specimen="{
					id: tournament.id,
					url: tournament.url_image,
				}"
			/>

			<h6 class="font-weight-bolder mb-0 clickable" style="width: 90%">
				<span v-if="tournament.award">
					<b-badge class="text-white" :variant="tournament.award.position_id > 1 ? 'warning' : 'success'">
						{{ tournament.award.position_name }}
						<v-icon
							v-if="tournament.award.position_id <= 3"
							name="fa-trophy"
							:style="{ color: colorizePosition(tournament.award.position_id) }"
						/>
					</b-badge>
				</span>
				{{ tournament.name }}
			</h6>
			<feather-icon
				class="text-dark icon"
				:class="[compact ? 'active-icon' : '']"
				icon="ChevronDownIcon"
				size="24"
				style="width: 24px"
			/>
		</div>
		<div
			class="position-relative justify-content-between d-flex pl-1 pr-1"
			:class="`${shoMore ? 'align-items-start' : ' align-items-start '} `"
		>
			<div style="width: 81%">
				<span v-if="!shoMore && tournament.description" style="width: 80%; font-size: 0.8rem">
					{{ tournament.description.substring(0, 42) }}
					{{ tournament.description.substring(0, 42).length === 42 ? "..." : "" }}
				</span>
				<span class="active" v-else style="width: 80%; font-size: 0.8rem">
					{{ tournament.description }}
				</span>
			</div>

			<div class="justify-content-end">
				<span
					v-if="tournament && tournament.description && tournament.description.length > 42"
					class="font-weight-bolder"
					style="font-size: 0.8rem; width: 100%; color: #7367f0"
					@click="showmore"
				>
					{{ shoMore ? "ver menos" : "ver mas" }}
				</span>
			</div>
		</div>
		<div
			class="container-card"
			:class="[compact ? 'active' : '', tournament.award && compact ? 'active-w-award' : '']"
		>
			<div class="p-1" style="position: relative">
				<div class="gap-x-2 grid-item">
					<span class="font-weight-bolder">INAUGURACION:</span>
					<span>{{ tournament.start_date | myGlobalDayShort }}</span>
				</div>
				<div class="gap-x-2 grid-item">
					<span class="font-weight-bolder">FINAL:</span>
					<span>{{ tournament.end_date | myGlobalDayShort }}</span>
				</div>
				<div class="gap-x-2 grid-item">
					<span class="font-weight-bolder">COLISEO:</span>
					<span v-b-tooltip.hover :title="tournament.coliseum" class="text-truncate text-nowrap">
						{{ tournament.coliseum }}
					</span>
				</div>
				<div class="gap-x-2 grid-item">
					<span class="font-weight-bolder">CUIDAD:</span>
					<span v-b-tooltip.hover :title="tournament.city || ''" class="text-truncate text-nowrap">
						{{ tournament.city || "Sin cuidad" }}
					</span>
				</div>
				<div class="gap-x-2 grid-item">
					<span class="font-weight-bolder">PREMIO:</span>
					<span>{{tournament.currency.name}} {{ fixedMoney(tournament.total_prize) }}</span>
				</div>

				<div class="d-flex justify-content-around mt-1" v-if="tournament.quantity_events != 0">
					<div class="text-center" @click="$emit('openEventsTournament')">
						<small class="">FECHAS:</small>
						<b-badge :variant="tournament.quantity_events > 0 ? 'primary' : ''" class="text-white ml-25">
							{{ tournament.quantity_events }}
						</b-badge>
					</div>
					<div class="d-flex align-items-center justify-content-center cursor-pointer">
						<small class="">FRENTES:</small>

						<b-badge
							@click="tournament.quantity_fronts > 0 && $emit('openModalFrente')"
							:variant="tournament.quantity_fronts > 0 ? 'primary' : ''"
							class="text-white ml-25"
						>
							{{ tournament.quantity_fronts }}
						</b-badge>
					</div>
				</div>
				<div class="grid-specimens mt-1 gap-y-2" v-if="tournament.specimens">
					<div class="d-flex align-items-center justify-content-center cursor-default">
						<small class="text-nowrap" v-b-tooltip.hover :title="'GALLOS REQUERIDOS'">G. REQUERIDOS:</small>
						<span class="mb-0 ml-25">{{ tournament.specimens.required }}</span>
					</div>
					<div class="d-flex align-items-center justify-content-center">
						<small class="text-nowrap" v-b-tooltip.hover :title="'GALLOS REGISTRADOS'">
							G. REGISTRADOS:
						</small>
						<b-badge
							:variant="tournament.specimens.registered > 0 ? 'primary' : ''"
							class="ml-25 clickable text-white"
							@click="tournament.specimens.registered > 0 && $emit('openSpecimensTournament')"
						>
							{{ tournament.specimens.registered }}
						</b-badge>
					</div>
				</div>
				<div class="d-flex justify-content-around mt-1" v-if="tournament.award">
					<div class="text-center">
						<small class="cursor-default">N. PREMIOS:</small>
						<span
							@click="tournament.award.counter > 0 && getAwardsTournament(tournament)"
							class="badge text-white ml-50"
							:class="[
								tournament.award.counter > 0
									? 'badge-primary cursor-pointer'
									: 'badge-secondary cursor-default',
							]"
						>
							{{ tournament.award.counter }}
						</span>
					</div>
				</div>
				<div class="d-flex gap-x-2 mt-1">
					<div
						class="square"
						:style="{ backgroundColor: bgColor.color, borderRadius: '5px', padding: '2px' }"
						v-if="tournament.quantity_events != 0"
					>
						<feather-icon class="text-white" icon="CheckIcon" size="24" />
					</div>
					<div
						class="square"
						:style="{ backgroundColor: 'red', borderRadius: '5px', padding: '2px' }"
						v-else-if="tournament.quantity_events == 0 && tournament.status != 4"
					>
						<feather-icon @click="cancelTournament(tournament)" class="text-white" icon="XIcon" size="24" />
					</div>
					<div class="square bg-secondary" style="border-radius: 5px; padding: 2px">
						<feather-icon
							@click="$emit('openModalDetails', tournament)"
							class="text-white"
							icon="EyeIcon"
							size="24"
						/>
					</div>
				</div>
			</div>

			<div class="dates px-1 py-1" :style="{ backgroundColor: bgColor.bg }">
				<div class="d-flex justify-content-around" v-if="tournament.specimens">
					<div class="text-center">
						<b class="">{{ tournament.start_date | myGlobalDayShort }}</b>
					</div>
					<span class="d-flex-grow">
						{{ " ─────▸ " }}
					</span>
					<div class="text-center">
						<b class="">{{ tournament.end_date | myGlobalDayShort }}</b>
					</div>
				</div>
			</div>
		</div>

		<b-modal
			hide-footer
			size="lg"
			v-model="modalAwards.show"
			v-if="modalAwards.show"
			centered
			:title="modalAwards.title"
		>
			<b-table-simple sticky-header="20rem">
				<b-thead class="text-center">
					<b-tr>
						<b-th>Puesto</b-th>
						<b-th>Premios</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(award, index) in modalAwards.awards" :key="index">
						<b-td>
							<b-badge class="text-white" :variant="award.num > 1 ? 'warning' : 'success'">
								{{ award.name }}
								<v-icon
									v-if="award.num <= 3"
									name="fa-trophy"
									:style="{ color: colorizePosition(award.num) }"
								/>
							</b-badge>
						</b-td>
						<b-td>
							<template v-if="award.awards && award.awards.length > 0">
								<b-row class="mb-05" v-for="(award, i) in award.awards" :key="i">
									<b-col class="text-right" cols="5">
										<b-badge class="text-white">{{ String(award.name).toUpperCase() }}</b-badge>
									</b-col>
									<b-col class="m-0 p-0" cols="2">:</b-col>
									<b-col class="text-left" cols="5">
										{{ award.name == "dinero" ? `S./ ${fixedMoney(award.value)}` : award.value }}
									</b-col>
								</b-row>
							</template>
							<p v-else class="mb-0">Sin premios</p>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-modal>
	</div>
</template>

<script>
import DetailsTournament from "./DetailsTournament.vue"
import { bus } from "@/main"
import tournamentService from "@/services/tournament/default.service"
import ViewImage from "@/components/commons/ViewImage.vue"

export default {
	components: { DetailsTournament, ViewImage },
	props: {
		tournament: {
			type: Object,
			required: true,
		},
		bg: {
			type: String,
			default: "#fff",
		},
		color: {
			type: String,
			default: "#fff",
		},
	},
	data() {
		return {
			compact: false,
			modalDetails: {
				show: false,
			},
			modalAwards: {
				show: false,
				title: "",
				id: null,
				awards: [],
			},
			shoMore: false,
		}
	},
	computed: {
		bgColor() {
			switch (this.tournament.status) {
				case 1:
					return {
						bg: "#FFFCDF",
						color: "#FFD913",
					}
				case 2:
					return {
						bg: "#D9FFC7",
						color: "#6FED33",
					}
				case 3:
					return {
						bg: "#DBE9FF",
						color: "#6DA6FF",
					}
				case 4:
					return {
						bg: "#FFE0E0",
						color: "#FF4E4E",
					}
				default:
					return {
						bg: "#FFFCDF",
						color: "#FFD913",
					}
			}
		},
	},
	methods: {
		async cancelTournament(tournament) {
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return
			this.isPreloading()
			const { status, message } = await tournamentService.setToCancelTournament({ id: tournament.id })
			this.isPreloading(false)
			if (status) {
				this.showToast("success", "top-right", "Torneos", "SuccessIcon", message)
				bus.$emit("reload-tournaments")
			}
		},
		colorizePosition(position) {
			switch (position) {
				case 1:
					return "#FFD700"
				case 2:
					return "#C0C0C0"
				case 3:
					return "#CD7F32"
				default:
					return "gray"
			}
		},
		async getAwardsTournament(row) {
			// console.log('ad', row)
			this.modalAwards.show = true
			this.modalAwards.title = `Premios obtenidos en el torneo ${row.name}`
			const { data } = await tournamentService.getAwardsTournament({ id: row.id })
			console.log("data", data)
			this.modalAwards.awards = data
		},
		showmore() {
			this.shoMore = !this.shoMore
		},
	},
}
</script>

<style scoped lang="scss">
.text-text {
	margin-left: 30px;
}

.container-item-canva {
	border-radius: 12px;
	.container-card {
		height: 46px;
		transition: 0.4s all ease-in-out;
		.dates {
			transition: 0.4s all ease-in-out;
			position: sticky;
			bottom: 0;
		}
	}
	.active {
		height: 280px;
		transition: 0.4s all ease-in-out;
		@media screen and (min-width: 1500px) {
			height: 240px;
		}
		.dates {
			// background: red !important;
			// display: none;
			transform: translateX(100%);
		}

		&-w-award {
			height: 310px;
			@media screen and (min-width: 1500px) {
				height: 270px;
			}
		}
	}
}

.container-text {
	height: 21px;
	transition: 0.4s all ease-in-out;
	.dates {
		transition: 0.4s all ease-in-out;
		position: sticky;
		bottom: 0;
	}
}
.actives {
	height: 200px;
	transition: 0.4s all ease-in-out;
	@media screen and (min-width: 1500px) {
		height: 120px;
	}
	.dates {
		// background: red !important;
		// display: none;
		transform: translateX(100%);
	}

	&-w-award {
		height: 310px;
		@media screen and (min-width: 1500px) {
			height: 270px;
		}
	}
}
span {
	color: #5e5e5e;
}
.grid-item {
	display: grid;
	grid-template-columns: 1fr 1.5fr;
}

.icon {
	transition: 0.4s all ease-in-out;
}
.active-icon {
	transform: rotate(180deg);
}
.grid-specimens {
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 1500px) {
		flex-direction: row;
		justify-content: space-around;
	}
}

.grid-awards {
	display: grid;
	grid-template-columns: 1fr 1fr;
	.name-award {
	}
	.value-award {
	}
}
</style>
