<template>
	<div>
		<b-sidebar
			no-close-on-backdrop
			width="40em"
			body-class="bg-white"
			backdrop
			right
			shadow
			v-model="isActive"
			@hidden="handleHidden"
		>
			<template #title>
				<div class="d-flex align-items-center gap-x-2" v-if="editingName">
					<feather-icon class="text-success clickable" icon="CheckIcon" size="30" @click="updateName" />
					<feather-icon
						class="text-danger clickable"
						icon="XIcon"
						size="30"
						@click=";(editingName = false), (newName = tournament.name)"
					/>
					<b-form-input class="d-inline-block" v-model="newName" />
				</div>
				<span style="font-size: 1.5rem" v-else>
					<feather-icon
						class="text-warning mr-1 clickable"
						icon="Edit3Icon"
						size="20"
						@click=";(editingName = true), (newName = tournament.name)"
					/>
					{{ !!tournament && tournament.name }}
				</span>
			</template>
			<b-overlay :show="isLoading">
				<b-row class="m-2">
					<b-col cols="12" class="">
						<div class="d-flex align-items-center gap-x-2 mb-2">
							<span class="h4 mb-0 title-form">1. Fechas</span>
						</div>
						<b-row>
							<b-col>
								<b-form-group label="Inauguracion">
									<b-form-datepicker
										class="border-primary"
										locale="en-US"
										placeholder="Agregar final"
										:date-format-options="{
											month: 'numeric',
											day: 'numeric',
											year: 'numeric',
										}"
										:max="tournament.limits.min ? tournament.limits.min : null"
										v-model="tournament.start_date"
									/>
								</b-form-group>
							</b-col>
							<b-col>
								<b-form-group label="Final">
									<b-form-datepicker
										class="border-primary"
										placeholder="Agregar final"
										:date-format-options="{
											month: 'numeric',
											day: 'numeric',
											year: 'numeric',
										}"
										:min="tournament.limits.max ? tournament.limits.max : null"
										v-model="tournament.end_date"
									/>
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>

					<b-col cols="12" class="mt-2">
						<div class="d-flex align-items-center gap-x-2 mb-2">
							<span class="h4 mb-0 title-form">2. Descripción</span>
						</div>
						<b-input-group>
							<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
								<b-form-input
									class="border-primary"
									:invalid-feedback="errors[0]"
									:state="errors[0] ? false : null"
									id="textarea"
									v-model="tournament.description"
									placeholder="Agregar comentario"
									rows="3"
									max-rows="6"
								></b-form-input>
								<small class="text-danger">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-input-group>
					</b-col>

					<b-col cols="12" class="mt-2">
						<h4 class="mb-0 title-form">
							3. Etapas
							<b-button
								class="float-right"
								size="sm"
								variant="primary"
								@click="stageTypesModal.show = !stageTypesModal.show"
							>
								<feather-icon icon="Edit3Icon" size="20" />
							</b-button>
						</h4>
						<template v-if="tournament.stages">
							<b-row class="mt-2">
								<b-col class="text-right" cols="5"><h4>Etapa</h4></b-col>
								<b-col cols="2"></b-col>
								<b-col cols="5"><h4>Cantidad de tipos</h4></b-col>
							</b-row>
							<b-row>
								<b-col class="text-right" cols="5">
									<span class="font-weight-bold">Pre-clasificación</span>
								</b-col>
								<b-col cols="2"></b-col>
								<b-col cols="5">{{ filterStages(1).length }}</b-col>
							</b-row>
							<b-row>
								<b-col class="text-right" cols="5">
									<span class="font-weight-bold">Clasificación</span>
								</b-col>
								<b-col cols="2"></b-col>
								<b-col cols="5">{{ filterStages(2).length }}</b-col>
							</b-row>
							<b-row>
								<b-col class="text-right" cols="5">
									<span class="font-weight-bold">Semifinal</span>
								</b-col>
								<b-col cols="2"></b-col>
								<b-col cols="5">{{ filterStages(3).length }}</b-col>
							</b-row>
							<b-row>
								<b-col class="text-right" cols="5">
									<span class="font-weight-bold">Final</span>
								</b-col>
								<b-col cols="2"></b-col>
								<b-col cols="5">{{ filterStages(4).length }}</b-col>
							</b-row>
						</template>
					</b-col>

					<b-col cols="12" class="mt-2">
						<div class="d-flex align-items-center gap-x-2 mb-2">
							<span class="h4 mb-0 title-form">4. Coliseo(s)</span>
						</div>
						<v-select
							multiple
							label="name"
							:options="arrayColiseums"
							:reduce="(opt) => opt.value"
							:value="tournament.coliseums"
							@option:selecting="handleColiseumSelect"
							@option:deselecting="handleColiseumDelete"
						>
							<template #selected-option-container="{ option }">
								<div :class="['vs__selected', option.canDelete ? 'bg-secondary' : '']">
									{{ option.name }}
									<feather-icon class="ml-05" icon="XIcon" @click="handleColiseumDelete(option)" />
								</div>
							</template>
						</v-select>
						<b-input-group>
							<b-form-radio-group
								class="mt-1"
								:checked="tournament.coliseumMain"
								:options="tournament.coliseums"
								text-field="name"
								value-field="value"
								@input="coliseumChange"
							/>
						</b-input-group>
					</b-col>

					<b-col cols="12" class="mt-2">
						<div class="d-flex align-items-center gap-x-2 mb-2">
							<span class="h4 mb-0 title-form">5. Organizadores</span>
						</div>
						<v-select
							multiple
							label="name"
							:options="arrayOrganizers"
							:reduce="(opt) => opt.value"
							:value="tournament.organizers"
							@option:selecting="handleOrganizerSelect"
							@option:deselecting="handleOrganizerDelete"
						/>
					</b-col>

					<b-col cols="12" class="mt-2">
						<div class="d-flex align-items-center gap-x-2 mb-2">
							<span class="h4 mb-0 title-form">6. Premio total</span>
						</div>
						<b-input-group>
							<div class="groupmoney">
								<v-select
									class="bg-white rounded"
									style="width: 4rem"
									label="name"
									:options="arrayCurrency"
									:reduce="(opt) => opt.id"
									:clearable="false"
									v-model="tournament.currency"
								/>
								<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
									<money
										v-bind="money"
										v-model="tournament.total_prize"
										class="form-control mr-50 border-primary"
										placeholder="Agregar precio"
										:style="{ width: '100%', textAlign: 'center' }"
									></money>
									<small class="text-danger">{{ errors[0] }}</small>
								</ValidationProvider>
							</div>
						</b-input-group>
					</b-col>

					<b-col cols="12" class="mt-2">
						<div class="d-flex align-items-center gap-x-2 mb-2">
							<span class="h4 mb-0 title-form">7. Comentario</span>
						</div>
						<b-input-group>
							<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
								<b-form-textarea
									class="border-primary"
									:invalid-feedback="errors[0]"
									:state="errors[0] ? false : null"
									id="textarea"
									v-model="tournament.comment"
									placeholder="Agregar comentario"
									rows="3"
									max-rows="6"
								></b-form-textarea>
								<small class="text-danger">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-input-group>
					</b-col>

					<b-col cols="12" class="mt-2">
						<div class="d-flex align-items-center gap-x-2 mb-2">
							<span class="h4 mb-0 title-form">8. Flyers</span>
						</div>
						<SelectableImages v-if="isActive" v-model="tournament.flyers" />
					</b-col>
					<b-col cols="12" class="mt-2">
						<div class="d-flex align-items-center gap-x-2 mb-2">
							<span class="h4 mb-0 title-form">9. Avatar</span>
						</div>
						<ValidationProvider rules="required" v-slot="{ errors }" class="w-30">
							<div class="container-avatar">
								<InputImages :multiple="false" single variant="primary" v-model="tournament.avatar" />

								<div class="icons-camera" @click="openModalImage()">
									<feather-icon icon="PlusIcon" style="color: white" />
								</div>
							</div>
							<small class="text-danger" v-if="errors[0]">
								{{ errors[0] }}
							</small>
						</ValidationProvider>
					</b-col>
				</b-row>
			</b-overlay>
			<template #footer>
				<div class="p-2 d-flex justify-content-end">
					<b-button @click="handleHidden" variant="danger" class="mr-1">Cancelar</b-button>
					<b-button @click="updateDetails" variant="success">Guardar</b-button>
				</div>
			</template>

			<StageTypes
				:show="stageTypesModal.show"
				:info="stageTypesModal.info"
				@closing="stageTypesModal.show = false"
				@new-types="setStages"
			/>
		</b-sidebar>

		<ModalSelectImages v-if="showModalImages" @close="showModalImages = false" @getImage="getImage" />
	</div>
</template>

<script>
import moment from "moment"
import { getBase64 } from "@/helpers/functions"
import tournamentService from "@/services/tournament/default.service"
import SelectableImages from "./SelectableImages.vue"
import coliseumService from "@/services/tournament/coliseum.service"
import contactService from "@/services/tournament/contacts.service"
import { Money } from "v-money"
import StageTypes from "./StageTypes.vue"
import InputImages from "@/components/commons/InputImages.vue"
import ModalSelectImages from "@/views/amg/tournaments/v2/components/ModalSelectImages.vue"

export default {
	name: "DetailsTournament",
	components: { SelectableImages, Money, StageTypes, InputImages, ModalSelectImages },
	props: {
		show: Boolean,
		tournament_id: Number,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		showModalImages: false,
		tournament: {
			name: "",
			start_date: "",
			end_date: "",
			stages: [],
			coliseums: [],
			coliseumMain: null,
			organizers: [],
			total_prize: "",
			comment: "",
			flyers: [],
			limits: { min: undefined, max: undefined },
			avatar: [],
			description: "",
			idImage: null,
			currency: null,

		},
		editingName: false,
		newName: null,
		arrayColiseums: [],
		arrayOrganizers: [],
		money: {
			decimal: ".",
			thousands: ",",
 			suffix: "",
			precision: 2,
			masked: false,
		},
		stageTypesModal: {
			show: false,
			info: { stages: [] },
		},
		arrayCurrency: [
			{
				id: 2,
				name: "S/.",
			},
			{
				id: 1,
				name: "$",
			},
		],
	}),

	async created() {
		await this.initOpts()

		if (this.tournament_id) {
			await this.initOpts()
			await this.init(this.tournament_id)
		}
	},
	methods: {
		getImage(val) {
			this.tournament.avatar = [
				{
					id: val.id,
					url: val.image,
				},
			]

			console.log(val)
		},
		openModalImage() {
			this.showModalImages = true
		},
		handleHidden() {
			this.isActive = false
			this.editingName = false
			this.newName = null
			this.tournament = {
				name: "",
				start_date: "",
				end_date: "",
				stages: [],
				coliseums: [],
				coliseumMain: null,
				organizers: [],
				total_prize: "",
				comment: "",
				flyers: [],
				limits: { min: undefined, max: undefined },
				avatar: [],
			}
			
			this.$emit("closing")
		},
		async init(id) {
			this.isLoading = true
			const { data } = await tournamentService.getTournamentById(id)
			const updatedFlyers = (data.flyers || []).map((it) => {
				let image = ""

				// Verificamos si el path tiene la extensión .pdf
				if (/\bpdf\b/i.test(it.path)) {
					// Si es un PDF, asignamos la URL de una imagen representativa de PDF
					image = "https://imprenta.wiki/wp-content/uploads/2017/10/PDF-300x294.jpg"
				}

				// Retornamos un nuevo objeto con la propiedad image agregada
				return {
					...it,
					main: it.main == 1 ? true : false,
					delete: false,
					path: image ? image : it.path,
					download: it.path,
					format: image ? "pdf" : "image",
				}
			})
			let fixedStages = []
			if (data.stages) fixedStages = data.stages.map((s) => ({ ...s.data, id: s.id }))
			this.tournament = {
				name: data.name,
				start_date: data.start_date,
				end_date: data.end_date,
				stages: fixedStages,
				coliseums: !!data.coliseums
					? data.coliseums.map((c) => ({ name: c.name, value: c.id, main: c.main, canDelete: c.disabled }))
					: [],
				coliseumMain: data.coliseums.find((c) => c.main == 1).id,
				organizers: !!data.contacts ? data.contacts.map((o) => ({ name: o.name, value: o.id })) : [],
				total_prize: data.total_prize,
				comment: data.comment,
				flyers: updatedFlyers,
				limits: data.dates || { min: null, max: null },
				avatar: !!data.avatar_name ? [{ id: data.avatar_id, url: data.avatar_name }] : [],
				description: data.description,
				currency: data.currency.id
			}
			if (this.tournament.limits.max) {
				var maxDate = new Date(this.tournament.limits.max)
				maxDate.setDate(maxDate.getDate() + 1)
				this.tournament.limits.max = maxDate
			}
			if (this.tournament.limits.min) {
				var minDate = new Date(this.tournament.limits.min)
				minDate.setDate(minDate.getDate() + 1)
				this.tournament.limits.min = minDate
			}
			this.stageTypesModal.info = { stages: fixedStages, _mode: "edit" }
			this.isLoading = false
		},
		async initOpts() {
			this.isLoading = true
			const { data: colliseums } = await coliseumService.getColiseumsToSelect({
				campo: null,
				pageIn: 1,
				perPageIn: 1000,
				status: 0,
			})
			this.arrayColiseums = colliseums.data.map((it) => ({ value: it.id_coliseum, name: it.name }))
			const { data: organizers } = await contactService.getContactsToStatus({
				campo: null,
				pageIn: 1,
				perPageIn: 10000,
				status: 0,
			})
			this.arrayOrganizers = organizers.data.map((it) => ({ value: it.id, name: it.name }))
			this.isLoading = false
		},
		async updateDetails() {
			if (this.tournament.avatar.length == 0) {
				this.showToast(
					"warning",
					"top-right",
					"Torneos",
					"AlertIcon",
					"Tiene que seleccionar un avatar para el torneo"
				)
				return
			}
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return

			this.isPreloading()
			const body = {
				comment: this.tournament.comment,
				total_prize: this.tournament.total_prize,
				description: this.tournament.description,
				newsFlyers: this.tournament.flyers
					.filter((it) => !it.id && !it.delete)
					.map((it) => ({ ...it, main: it.main ? 1 : 0, id: null })),
				deleteFlyers: this.tournament.flyers
					.filter((it) => it.delete && it.id)
					.map((it) => it.id)
					.join(","),
				stages: [],
				flyersOld: this.tournament.flyers
					.filter((it) => it.id && !it.delete)
					.map((it) => ({ ...it, main: it.main ? 1 : 0 })),
				organizers: this.tournament.organizers.map((o) => o.value),
				coliseums: this.tournament.coliseums.map((c) => {
					return {
						id: c.value,
						main: c.value == this.tournament.coliseumMain ? 1 : 0,
					}
				}),
				start_date: moment(this.tournament.start_date).format("YYYY-MM-DD"),
				end_date: moment(this.tournament.end_date).format("YYYY-MM-DD"),
				currency_id: this.tournament.currency
			}
			if (this.tournament.avatar[0] && this.tournament.avatar[0].file) {
				body.avatar = {
					id: null,
					image: await getBase64(this.tournament.avatar[0].file),
				}
			} else {
				body.avatar = {
					id: (body.avatar = this.tournament.avatar[0].id),
					image: null,
				}
			}
			const res = await tournamentService.updateDetailsTournament({
				body,
				id: this.tournament_id,
			})
			this.isPreloading(false)
			this.$emit("refresh")
			this.handleHidden()
		},
		coliseumChange(e) {
			this.tournament.coliseumMain = e
		},
		handleColiseumSelect(e) {
			let repeated = this.tournament.coliseums.some((c) => c.value == e.value)
			if (repeated) return
			this.tournament.coliseums.push(e)
		},
		handleColiseumDelete(e) {
			if (e.canDelete) {
				this.showToast(
					"warning",
					"top-right",
					"Torneos",
					"AlertIcon",
					"No se puede eliminar un coliseo que tiene fechas asignadas."
				)
				return
			}
			let wasMain = e.main == 1
			let idx = this.tournament.coliseums.findIndex((opt) => opt.value == e.value)
			this.tournament.coliseums.splice(idx, 1)
			if (wasMain && this.tournament.coliseums.length > 0) {
				// reassign main
				this.tournament.coliseumMain = this.tournament.coliseums[0].id
				this.tournament.coliseums[0].main = 1
			}
		},
		async updateName() {
			this.isLoading = true
			await tournamentService.updateTournamentName({ tournament_id: this.info.id, name: this.newName })
			this.editingName = false
			this.newName = null
			this.init(this.info.id)
			this.$emit("refresh")
			this.isLoading = false
		},
		handleOrganizerSelect(e) {
			let repeated = this.tournament.organizers.some((o) => o.value == e.value)
			if (repeated) return
			this.tournament.organizers.push(e)
		},
		handleOrganizerDelete(e) {
			let idx = this.tournament.organizers.findIndex((o) => o.value == e.value)
			this.tournament.organizers.splice(idx, 1)
		},
		async setStages(stages) {
			this.tournament.stages = stages
			const resp = await tournamentService.updateStagesByTournament({
				stages: stages.map((it) => ({ ...it, delete: it.delete ? 1 : 0 })),
				tournament_id: this.tournament_id,
			})
			this.stageTypesModal.info = { stages, _mode: "edit" }
		},
		filterStages(type) {
			// removes deleted rows and filter by stage
			return this.tournament.stages.filter((s) => !s.delete).filter((s) => s.stage_id == type)
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
			}
		},
		async tournament_id() {
			if (this.tournament_id) {
				await this.init(this.tournament_id)
			}
		},
	},
}
</script>

<style scoped lang="scss">
.title-form {
	font-family: "Rubik" !important;
	font-size: 20px;
}
.container-avatar {
	display: flex;
	align-items: center;
}

.icons-camera {
	width: 30px;
	height: 30px;
	background-color: #7367f0;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	border-radius: 5px;
	cursor: pointer;
}

.Sau {
	display: flex;
	gap: 1rem;
	min-width: 7rem;
	max-width: 16rem;
	padding-top: 1rem;
	overflow-x: auto;

	&__box {
		flex-shrink: 0;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 6rem;
		height: 7rem;
		border-radius: 0.5rem;
		border: 2px solid var(--primary);
		gap: 1rem;
		margin-top: 1rem;
		margin-right: 1rem;

		&--dashed {
			border-style: dashed;
			border-color: var(--secondary);
		}
	}

	&__indication {
		text-align: center;
		font-size: 0.9rem;
	}

	&__input {
		position: absolute;
		opacity: 0;
		inset: 0;
		border: 2px solid pink;
		cursor: pointer;
	}

	&__img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
		cursor: pointer;
	}

	&__delete {
		position: absolute;
		top: -0.5rem;
		right: -0.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 1.5rem;
		height: 1.5rem;
		background: var(--danger);
		color: var(--white);
		border-radius: 50%;
		cursor: pointer;
	}
}
.groupmoney {
	display: flex;
	width: 100%;
}
</style>
