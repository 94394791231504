<template>
	<b-modal centered hide-footer size="lg" :title="`FECHAS / ${info.name}`" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-table-simple sticky-header="60vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th class="bg-transparent" style="border-top: none" colspan="2"></b-th>
						<b-th class="bg-primary text-white font-weight-bolder" colspan="3">Frentes</b-th>
					</b-tr>
					<b-tr>
						<b-th class="pr-25 pl-25">Fecha</b-th>
						<b-th class="pr-25 pl-25">Etapa</b-th>
						<b-th class="pr-25 pl-25">Registrados</b-th>
						<b-th class="pr-25 pl-25">Finalizados</b-th>
						<b-th class="pr-25 pl-25">Clasificados/Gano</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(row, i) in rows" :key="i">
						<b-td>
							<!-- <pre>{{ row }}</pre> -->
							{{ row.date | myGlobalDayShort }}
						</b-td>
						<b-td>{{ row.stage }}</b-td>
						<b-td>{{ row.fronts.registered }}</b-td>
						<b-td>{{ row.fronts.finished }}</b-td>
						<b-td>
							<p :class="[row.stage_id == 4 && row.fronts.classify == 1 ? 'badge badge-primary cursor-pointer' : '']" v-b-tooltip :title="row.fronts.text_classify" class="mb-0" @click="viewAwards(row)">
								{{ row.fronts.classify }}
							</p>
						</b-td>
					</b-tr>
					<b-tr v-if="rows.lenght == 0">
						<b-td colspan="2">No hay registros para mostrarI</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>

		<AwardsListModal
			:show="awardsModal.show"
			:info="awardsModal.info"
			@closing="awardsModal.show = false"
			@refresh="getRows"
			:all="awardsModal.all"
		/>
	</b-modal>
</template>

<script>
import tournamentService from "@/services/tournament/default.service"
import AwardsListModal from "@/views/amg/tournaments/v2/components/AwardsListModal.vue"

export default {
	components: { AwardsListModal },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		rows: [],
		awardsModal: { show: false, info: {}, all: false },
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.rows = []
			this.$emit("closing")
		},
		async getRows() {
			this.isLoading = true
			const { data } = await tournamentService.getEventsResumeByTournament({ tournament_id: this.info.id })
			this.rows = data
			this.isLoading = false
		},
		viewAwards(row, all = false) {
			console.log("info", row)

			if (row.stage_id == 4 && row.fronts.classify == 1) {
				this.awardsModal.show = !this.awardsModal.show
				this.awardsModal.info = {
					tournament: row.tournament,
					coliseum: row.coliseum,
					date: row.date,
					position_id: 1,
					id: row.id,
				}
				this.awardsModal.all = all
			}
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getRows()
			}
		},
	},
}
</script>
