<template>
	<b-modal centered hide-footer size="xl" :title="`FRENTES / ${info.name}`" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-table-simple sticky-header="60vh">
				<b-thead class="text-center">
					<!-- <b-tr>
						<b-th class="bg-transparent" style="border-top: none" colspan="2"></b-th>
						<b-th class="bg-primary text-white font-weight-bolder" colspan="3">Frentes</b-th>
					</b-tr> -->
					<b-tr>
						<b-th class="pr-25 pl-25">#</b-th>

						<b-th class="pr-25 pl-25">Fecha</b-th>
						<b-th class="pr-25 pl-25">Etapa</b-th>
						<b-th class="pr-25 pl-25">Tipo</b-th>
						<b-th class="pr-25 pl-25">METODO</b-th>
						<b-th class="pr-25 pl-25">PESO</b-th>
						<b-th class="pr-25 pl-25">ESTADO</b-th>
						<b-th class="pr-25 pl-25">CLASIFICADO</b-th>
						<b-th class="pr-25 pl-25">monto</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="(row, i) in rows" :key="i">
						<b-td>
							{{ i + 1 }}
						</b-td>
						<b-td>
							{{ row.event.date | myGlobalDayShort }}
						</b-td>
						<b-td>{{ row.event.stage.name }}</b-td>
						<b-td>{{ row.event.type.name }}</b-td>
						<b-td>{{ row.event.method }}</b-td>
						<b-td>
							<span v-if="row.event.weight && row.event.weight !== 'null'">{{ row.event.weight }}</span>
							<span v-else>NO APLICA</span>
						</b-td>
						<b-td>{{ row.event.status }}</b-td>
						<b-td>{{ row.classify }}</b-td>
						<b-td>{{ row.currency.name }} {{ row.amount }}</b-td>
					</b-tr>
					<b-tr v-if="rows.lenght == 0">
						<b-td colspan="2">No hay registros para mostrarI</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
	</b-modal>
</template>

<script>
import tournamentService from "@/services/tournament/default.service"

export default {
	components: {},
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		rows: [],
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.rows = []
			this.$emit("closing")
		},
		async getRows() {
			this.isLoading = true
			const { data } = await tournamentService.getFronts({ tournament_id: this.info.id })
			this.rows = data
			this.isLoading = false
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getRows()
			}
		},
	},
}
</script>
