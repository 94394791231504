<template>
	<b-modal
		centered
		hide-footer
		size="xmd"
		:title="`Ejemplares del torneo ${info.name}`"
		v-model="isActive"
		@hidden="handleHidden"
	>
		<b-overlay :show="isLoading">
			<b-row>
				<b-col>
					<b-tabs v-model="tab" @input="getSpecimens">
						<b-tab>
							<template #title>
								<span>Sin jugar</span>
								<b-badge class="ml-1" variant="danger" v-if="counters.unplayed != 0">
									{{ counters.unplayed }}
								</b-badge>
							</template>
						</b-tab>
						<b-tab :disabled="counters.played == 0">
							<template #title>
								<span>Jugados</span>
								<b-badge class="ml-1" variant="danger" v-if="counters.played != 0">
									{{ counters.played }}
								</b-badge>
							</template>
						</b-tab>
					</b-tabs>
				</b-col>
			</b-row>
			<b-row>
				<template v-for="(specimen, j) in specimens">
					<b-col class="mb-1" cols="6" md="5" lg="4" xl="3" :key="j" v-if="!specimen.delete">
						<div class="position-relative clickable hoverTrigger flex-column">
							<SpecimenPlate :specimen="specimen" />
							<div class="text-center mt-05">
								<b-badge variant="primary">{{ specimen.date_event | myGlobalDayShort }}</b-badge>
								<template v-if="tab == 1">
									<br />
									<b-badge class="text-uppercase mt-25" variant="secondary">
										{{ specimen.result }} / {{ specimen.status }}
									</b-badge>
								</template>
								<!-- <template v-if="specimen.nursing_status_id">
									<br />
									<b-badge class="text-uppercase mt-25" variant="secondary">En enfermeria</b-badge>
								</template> -->

								<div style="position: absolute; top: 0; right: 0">
									<b-button
										v-if="[0, 1].includes(tab) && !specimen.nursing_status_id"
										v-b-tooltip.hover
										title="Enviar ejemplar a enfermeria"
										class="btn-icon"
										size="sm"
										variant="flat-danger"
										@click="
											;(addNursery.info = { specimen: specimen }),
												(addNursery.show = !addNursery.show)
										"
									>
										<v-icon name="md-healthandsafety-outlined" scale="1" class="text-danger" />
									</b-button>
									<div
										class="mt-50"
										v-b-tooltip.hover
										title="Ejemplar en espera"
										v-if="specimen.nursing_status_id == 1"
									>
										<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-info" />
									</div>
									<div
										class="mt-50"
										v-b-tooltip.hover
										title="Ejemplar en proceso"
										v-else-if="specimen.nursing_status_id == 2"
									>
										<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-success" />
									</div>
								</div>
							</div>
						</div>
					</b-col>
				</template>
				<b-col cols="12" v-if="specimens.length == 0">
					<p class="text-center mb-0">Sin registros</p>
				</b-col>
				<b-col cols="12">
					<div class="ml-2 mt-1 mb-1">
						<h5 class="mb-0">Enfermería</h5>

						<div class="d-flex align-items-center justify-content-start mt-50">
							<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-danger" />
							<small class="mb-0 ml-25">Enviar ejemplar a enfermeria</small>
						</div>

						<div class="d-flex align-items-center justify-content-start mt-50">
							<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-info" />
							<small class="mb-0 ml-25">Ejemplar en espera</small>
						</div>

						<div class="d-flex align-items-center justify-content-start mt-50">
							<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-success" />
							<small class="mb-0 ml-25">Ejemplar en proceso</small>
						</div>
					</div>
				</b-col>
			</b-row>
		</b-overlay>

		<AddSpecimensNursery
			:show="addNursery.show"
			:info="addNursery.info"
			@closing="addNursery.show = false"
			@refresh="getSpecimens"
		/>
	</b-modal>
</template>

<script>
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import tournamentService from "@/services/tournament/default.service"
import AddSpecimensNursery from "@/views/amg/nursery/components/AddSpecimensNursery.vue"

export default {
	components: { SpecimenPlate, AddSpecimensNursery },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		tab: 0,
		counters: {
			played: 0,
			unplayed: 0,
		},
		specimens: [],
		addNursery: { show: false, info: {} },
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.tab = 0
			this.counters = {
				played: 0,
				unplayed: 0,
			}
			this.specimens = []
			this.$emit("closing")
		},
		async getCounters() {
			this.isLoading = true
			const { data } = await tournamentService.getSpecimensByTournamentCounters({
				tournament_id: this.info.id,
				event_id: this.info.event_id || null,
			})
			this.counters.played = data.find((c) => c.status_id == 1).counter
			this.counters.unplayed = data.find((c) => c.status_id == 2).counter
			this.isLoading = false
			if (this.counters.unplayed == 0) {
				this.tab = 1
			}
		},
		async getSpecimens() {
			this.isLoading = true
			const { data } = await tournamentService.getSpecimensByTournament({
				tournament_id: this.info.id,
				status_id: this.tab == 0 ? 2 : 1,
				event_id: this.info.event_id || null,
			})
			this.specimens = data
			await this.getCounters()
			this.isLoading = false
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getSpecimens()
			}
		},
	},
}
</script>
