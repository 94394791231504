<template>
	<div style="background: #f3f2f7; border: 1px solid #cecece; border-radius: 0 8px 0 0" class="pt-50 pt-md-2">
		<b-overlay :show="isLoading">
			<b-row class="mb-2 mr-2">
				<b-col class="d-flex justify-content-end"></b-col>
			</b-row>
			<b-row style="max-height: 60vh; overflow-y: auto" class="mx-0 mx-md-1 m-md-2 pb-1 pb-md-2">
				<b-col cols="12" md="6" xl="3" class="mb-3 mb-xl-0">
					<b-row class="mx-25 px-0 py-1 container-canva">
						<b-col cols="12" class="border-none">
							<div class="p-1 header" style="background: #fff385">
								<h4 class="text-center text-dark font-weight-bolder mb-0">POR JUGAR</h4>
							</div>
						</b-col>
						<b-col v-for="t in tournamentsIncoming" :key="t.id" cols="12" class="mt-1">
							<CardCanvan
								:tournament="t"
								@openModalDetails="openModalDetails"
								@openSpecimensTournament="
									;(specimensTournamentModal.info = t),
										(specimensTournamentModal.show = !specimensTournamentModal.show)
								"
								@openEventsTournament="
									;(eventsTournamentModal.info = t),
										(eventsTournamentModal.show = !eventsTournamentModal.show)
								"
								@openModalFrente="
									;(eventsNephewsModal.info = t), (eventsNephewsModal.show = !eventsNephewsModal.show)
								"
							/>
						</b-col>
						<b-col v-if="tournamentsIncoming.length == 0" cols="12" class="mt-1">
							<p class="mb-0 text-center font-weight-bold">Sin torneos</p>
						</b-col>
					</b-row>
				</b-col>
				<b-col cols="12" md="6" xl="3" class="mb-3 mb-xl-0">
					<b-row class="mx-25 px-0 py-1 container-canva">
						<b-col cols="12">
							<div class="p-1 header" style="background: #a6ff7c">
								<h4 class="text-center text-dark font-weight-bolder mb-0">JUGANDO</h4>
							</div>
						</b-col>
						<b-col v-for="t in tournamentsCurrent" :key="t.id" cols="12" class="mt-1">
							<CardCanvan
								:tournament="t"
								@openModalDetails="openModalDetails"
								@openSpecimensTournament="
									;(specimensTournamentModal.info = t),
										(specimensTournamentModal.show = !specimensTournamentModal.show)
								"
								@openEventsTournament="
									;(eventsTournamentModal.info = t),
										(eventsTournamentModal.show = !eventsTournamentModal.show)
								"
								@openModalFrente="
									;(eventsNephewsModal.info = t), (eventsNephewsModal.show = !eventsNephewsModal.show)
								"
							/>
						</b-col>
						<b-col v-if="tournamentsCurrent.length == 0" cols="12" class="mt-1">
							<p class="mb-0 text-center font-weight-bold">Sin torneos</p>
						</b-col>
					</b-row>
				</b-col>
				<b-col cols="12" md="6" xl="3" class="mb-3 mb-xl-0">
					<b-row class="mx-25 px-0 py-1 container-canva">
						<b-col cols="12">
							<div class="p-1 header" style="background: #9ac2ff">
								<h4 class="text-center text-dark font-weight-bolder mb-0">FINALIZADOS</h4>
							</div>
						</b-col>
						<b-col v-for="t in tournamentsFinished" :key="t.id" cols="12" class="mt-1">
							<CardCanvan
								:tournament="t"
								@openModalDetails="openModalDetails"
								@openSpecimensTournament="
									;(specimensTournamentModal.info = t),
										(specimensTournamentModal.show = !specimensTournamentModal.show)
								"
								@openEventsTournament="
									;(eventsTournamentModal.info = t),
										(eventsTournamentModal.show = !eventsTournamentModal.show)
								"
								@openModalFrente="
									;(eventsNephewsModal.info = t), (eventsNephewsModal.show = !eventsNephewsModal.show)
								"
							/>
						</b-col>
						<b-col v-if="tournamentsFinished.length == 0" cols="12" class="mt-1">
							<p class="mb-0 text-center font-weight-bold">Sin torneos</p>
						</b-col>
					</b-row>
				</b-col>
				<b-col cols="12" md="6" xl="3" class="mb-3 mb-xl-0">
					<b-row class="mx-25 px-0 py-1 container-canva">
						<b-col cols="12">
							<div class="p-1 header" style="background: #ff9999">
								<h4 class="text-center text-dark font-weight-bolder mb-0">CANCELADOS</h4>
							</div>
						</b-col>
						<b-col v-for="t in tournamentsCanceled" :key="t.id" cols="12" class="mt-1">
							<CardCanvan
								:tournament="t"
								@openModalDetails="openModalDetails"
								@openPerTournament="
									;(specimensTournamentModal.info = t),
										(specimensTournamentModal.show = !specimensTournamentModal.show)
								"
								@openModalFrente="
									;(eventsNephewsModal.info = t), (eventsNephewsModal.show = !eventsNephewsModal.show)
								"
							/>
						</b-col>
						<b-col v-if="tournamentsCanceled.length == 0" cols="12" class="mt-1">
							<p class="mb-0 text-center font-weight-bold">Sin torneos</p>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</b-overlay>
		<AddTournament
			v-if="modalTorneo"
			:show="modalTorneo"
			@closing="modalTorneo = false"
			@refresh="getTournaments"
		/>
		<ShowTournament
			:show="modalDetails.show"
			:tournament_id="modalDetails.tournament_id"
			:info="modalDetails.info"
			@closing="
				modalDetails.show = false
				modalDetails.tournament_id = null
				getTournaments()
			"
			@refresh="getTournaments"
		/>
		<SpecimensPerTournamentModal
			:show="specimensTournamentModal.show"
			:info="specimensTournamentModal.info"
			@closing="specimensTournamentModal.show = false"
		/>
		<EventsPerTournamentModal
			:show="eventsTournamentModal.show"
			:info="eventsTournamentModal.info"
			@closing="eventsTournamentModal.show = false"
		/>
		<EventsFrenteModal
			:show="eventsNephewsModal.show"
			:info="eventsNephewsModal.info"
			@closing="eventsNephewsModal.show = false"
		/>
	</div>
</template>

<script>
import { bus } from "@/main"
import tournamentService from "@/services/tournament/default.service"
import DetailsTournament from "../components/DetailsTournament.vue"
import AddTournament from "../components/AddTournament.vue"
import CardCanvan from "../components/CardCanvan.vue"
import ShowTournament from "../components/ShowTournament.vue"
import SpecimensPerTournamentModal from "../components/SpecimensPerTournamentModal.vue"
import EventsPerTournamentModal from "../components/EventsPerTournamentModal.vue"
import EventsFrenteModal from "@/views/amg/tournaments/v2/components/EventsFrenteModal.vue"

export default {
	name: "Canvan",
	components: {
		AddTournament,
		CardCanvan,
		DetailsTournament,
		ShowTournament,
		SpecimensPerTournamentModal,
		EventsPerTournamentModal,
		EventsFrenteModal,
	},
	props: { season: Object },
	data: () => ({
		isLoading: false,
		modalTorneo: false,
		tournaments: [],
		modalDetails: {
			show: false,
			tournament_id: null,
			info: null,
		},
		modalDetails: {
			show: false,
			tournament_id: null,
			info: null,
		},
		specimensTournamentModal: {
			show: false,
			info: {},
		},
		eventsTournamentModal: {
			show: false,
			info: {},
		},

		eventsNephewsModal: {
			show: false,
			info: {},
		},
	}),
	computed: {
		tournamentsIncoming() {
			return this.tournaments.filter((t) => t.status == 1)
		},
		tournamentsCurrent() {
			return this.tournaments.filter((t) => t.status == 2)
		},
		tournamentsFinished() {
			return this.tournaments.filter((t) => t.status == 3)
		},
		tournamentsCanceled() {
			return this.tournaments.filter((t) => t.status == 4)
		},
	},
	methods: {
		async getTournaments() {
			this.isLoading = true
			try {
				const { data } = await tournamentService.getTournaments({
					start_date: this.season.start,
					end_date: this.season.end,
				})
				this.tournaments = data.data
			} catch (error) {
				console.log("error: ", error)
			} finally {
				this.isLoading = false
			}
		},
		openModalDetails(item) {
			this.modalDetails.show = !this.modalDetails.show
			this.modalDetails.tournament_id = item.id
			this.modalDetails.info = item
		},
	},
	created() {
		this.getTournaments()
		bus.$on("reload-tournaments", async () => {
			this.getTournaments()
		})
	},
	destroyed() {
		bus.$off("reload-tournaments")
	},
}
</script>

<style scoped lang="scss">
.container-canva {
	box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	border-radius: 12px;
	overflow: hidden;
	background: #fff;
	.header {
		border-radius: 12px;

		border: none;
		h4 {
			color: #5e5e5e;
		}
	}
}
</style>
