var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-50 pt-md-2",staticStyle:{"background":"#f3f2f7","border":"1px solid #cecece","border-radius":"0 8px 0 0"}},[_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('b-row',{staticClass:"mb-2 mr-2"},[_c('b-col',{staticClass:"d-flex justify-content-end"})],1),_c('b-row',{staticClass:"mx-0 mx-md-1 m-md-2 pb-1 pb-md-2",staticStyle:{"max-height":"60vh","overflow-y":"auto"}},[_c('b-col',{staticClass:"mb-3 mb-xl-0",attrs:{"cols":"12","md":"6","xl":"3"}},[_c('b-row',{staticClass:"mx-25 px-0 py-1 container-canva"},[_c('b-col',{staticClass:"border-none",attrs:{"cols":"12"}},[_c('div',{staticClass:"p-1 header",staticStyle:{"background":"#fff385"}},[_c('h4',{staticClass:"text-center text-dark font-weight-bolder mb-0"},[_vm._v("POR JUGAR")])])]),_vm._l((_vm.tournamentsIncoming),function(t){return _c('b-col',{key:t.id,staticClass:"mt-1",attrs:{"cols":"12"}},[_c('CardCanvan',{attrs:{"tournament":t},on:{"openModalDetails":_vm.openModalDetails,"openSpecimensTournament":function($event){;(_vm.specimensTournamentModal.info = t),
									(_vm.specimensTournamentModal.show = !_vm.specimensTournamentModal.show)},"openEventsTournament":function($event){;(_vm.eventsTournamentModal.info = t),
									(_vm.eventsTournamentModal.show = !_vm.eventsTournamentModal.show)},"openModalFrente":function($event){;(_vm.eventsNephewsModal.info = t), (_vm.eventsNephewsModal.show = !_vm.eventsNephewsModal.show)}}})],1)}),(_vm.tournamentsIncoming.length == 0)?_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0 text-center font-weight-bold"},[_vm._v("Sin torneos")])]):_vm._e()],2)],1),_c('b-col',{staticClass:"mb-3 mb-xl-0",attrs:{"cols":"12","md":"6","xl":"3"}},[_c('b-row',{staticClass:"mx-25 px-0 py-1 container-canva"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"p-1 header",staticStyle:{"background":"#a6ff7c"}},[_c('h4',{staticClass:"text-center text-dark font-weight-bolder mb-0"},[_vm._v("JUGANDO")])])]),_vm._l((_vm.tournamentsCurrent),function(t){return _c('b-col',{key:t.id,staticClass:"mt-1",attrs:{"cols":"12"}},[_c('CardCanvan',{attrs:{"tournament":t},on:{"openModalDetails":_vm.openModalDetails,"openSpecimensTournament":function($event){;(_vm.specimensTournamentModal.info = t),
									(_vm.specimensTournamentModal.show = !_vm.specimensTournamentModal.show)},"openEventsTournament":function($event){;(_vm.eventsTournamentModal.info = t),
									(_vm.eventsTournamentModal.show = !_vm.eventsTournamentModal.show)},"openModalFrente":function($event){;(_vm.eventsNephewsModal.info = t), (_vm.eventsNephewsModal.show = !_vm.eventsNephewsModal.show)}}})],1)}),(_vm.tournamentsCurrent.length == 0)?_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0 text-center font-weight-bold"},[_vm._v("Sin torneos")])]):_vm._e()],2)],1),_c('b-col',{staticClass:"mb-3 mb-xl-0",attrs:{"cols":"12","md":"6","xl":"3"}},[_c('b-row',{staticClass:"mx-25 px-0 py-1 container-canva"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"p-1 header",staticStyle:{"background":"#9ac2ff"}},[_c('h4',{staticClass:"text-center text-dark font-weight-bolder mb-0"},[_vm._v("FINALIZADOS")])])]),_vm._l((_vm.tournamentsFinished),function(t){return _c('b-col',{key:t.id,staticClass:"mt-1",attrs:{"cols":"12"}},[_c('CardCanvan',{attrs:{"tournament":t},on:{"openModalDetails":_vm.openModalDetails,"openSpecimensTournament":function($event){;(_vm.specimensTournamentModal.info = t),
									(_vm.specimensTournamentModal.show = !_vm.specimensTournamentModal.show)},"openEventsTournament":function($event){;(_vm.eventsTournamentModal.info = t),
									(_vm.eventsTournamentModal.show = !_vm.eventsTournamentModal.show)},"openModalFrente":function($event){;(_vm.eventsNephewsModal.info = t), (_vm.eventsNephewsModal.show = !_vm.eventsNephewsModal.show)}}})],1)}),(_vm.tournamentsFinished.length == 0)?_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0 text-center font-weight-bold"},[_vm._v("Sin torneos")])]):_vm._e()],2)],1),_c('b-col',{staticClass:"mb-3 mb-xl-0",attrs:{"cols":"12","md":"6","xl":"3"}},[_c('b-row',{staticClass:"mx-25 px-0 py-1 container-canva"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"p-1 header",staticStyle:{"background":"#ff9999"}},[_c('h4',{staticClass:"text-center text-dark font-weight-bolder mb-0"},[_vm._v("CANCELADOS")])])]),_vm._l((_vm.tournamentsCanceled),function(t){return _c('b-col',{key:t.id,staticClass:"mt-1",attrs:{"cols":"12"}},[_c('CardCanvan',{attrs:{"tournament":t},on:{"openModalDetails":_vm.openModalDetails,"openPerTournament":function($event){;(_vm.specimensTournamentModal.info = t),
									(_vm.specimensTournamentModal.show = !_vm.specimensTournamentModal.show)},"openModalFrente":function($event){;(_vm.eventsNephewsModal.info = t), (_vm.eventsNephewsModal.show = !_vm.eventsNephewsModal.show)}}})],1)}),(_vm.tournamentsCanceled.length == 0)?_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0 text-center font-weight-bold"},[_vm._v("Sin torneos")])]):_vm._e()],2)],1)],1)],1),(_vm.modalTorneo)?_c('AddTournament',{attrs:{"show":_vm.modalTorneo},on:{"closing":function($event){_vm.modalTorneo = false},"refresh":_vm.getTournaments}}):_vm._e(),_c('ShowTournament',{attrs:{"show":_vm.modalDetails.show,"tournament_id":_vm.modalDetails.tournament_id,"info":_vm.modalDetails.info},on:{"closing":function($event){_vm.modalDetails.show = false
			_vm.modalDetails.tournament_id = null
			_vm.getTournaments()},"refresh":_vm.getTournaments}}),_c('SpecimensPerTournamentModal',{attrs:{"show":_vm.specimensTournamentModal.show,"info":_vm.specimensTournamentModal.info},on:{"closing":function($event){_vm.specimensTournamentModal.show = false}}}),_c('EventsPerTournamentModal',{attrs:{"show":_vm.eventsTournamentModal.show,"info":_vm.eventsTournamentModal.info},on:{"closing":function($event){_vm.eventsTournamentModal.show = false}}}),_c('EventsFrenteModal',{attrs:{"show":_vm.eventsNephewsModal.show,"info":_vm.eventsNephewsModal.info},on:{"closing":function($event){_vm.eventsNephewsModal.show = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }