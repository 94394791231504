<template>
	<b-modal centered hide-footer size="lg" :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<!--  -->
			<div class="accordion" role="tablist">
				<template v-for="(row, i) in rows">
					<b-card
						no-body
						class="mb-1 border-primary card-border"
						:key="`front-${i}`"
						v-if="!row._blocked && (all ? true : row.num == info.position_id)"
					>
						<b-card-header
							header-tag="header"
							class="w-100 p-0 p-md-50 bg-primary"
							role="tab"
							@click="changeAccordion(i)"
						>
							<!-- <b-button class="w-100" variant="primary" block >
							
							</b-button> -->
							<h3 class="text-center mb-0 w-100 text-white">Frente #{{ i + 1 }}</h3>
						</b-card-header>

						<b-card-body class="mt-2">
							<b-table-simple sticky-header="20rem">
								<b-thead class="text-center">
									<b-tr>
										<b-th>Puesto</b-th>
										<!-- <b-th>Descripcion</b-th> -->
										<b-th>Premios</b-th>
									</b-tr>
								</b-thead>
								<b-tbody class="text-center">
									<b-tr>
										<b-td>
											<b-badge variant="warning" class="ml-05">
												{{ row.name }}
											</b-badge>
											<v-icon
												v-if="row.num < 4"
												name="fa-trophy"
												scale="1.2"
												class="cursor-pointer ml-25"
												:style="{ color: colorizePosition(row.num) }"
											/>
										</b-td>
										<!-- <b-td>{{ row.description[0] || "---" }}</b-td> -->
										<b-td>
											<template v-if="row.awards && row.awards.length > 0">
												<b-row class="mb-05" v-for="(award, i) in row.awards" :key="i">
													<b-col class="text-right" cols="5">
														<b-badge>{{ String(award.type).toUpperCase() }}</b-badge>
													</b-col>
													<b-col class="m-0 p-0" cols="2">:</b-col>
													<b-col class="text-left" cols="5">
														<!-- <span v-if="award.type == 'dinero'">S./</span> -->
														{{
															award.type == "dinero"
																? `${award.currency.name} ${fixedMoney(award.value)}`
																: award.value
														}} 
													</b-col>
												</b-row>
											</template>
											<p v-else class="mb-0">Sin premios</p>
										</b-td>
									</b-tr>
									<b-tr v-if="false">
										<b-td class="text-center" colspan="5">No hay datos para mostrar</b-td>
									</b-tr>
								</b-tbody>
							</b-table-simple>
						</b-card-body>

						<!-- <b-collapse accordion="my-accordion" role="tabpanel" v-model="row._open">
							
						</b-collapse> -->
					</b-card>
				</template>
			</div>
		</b-overlay>
	</b-modal>
</template>

<script>
import moment from "moment"

import tournamentService from "@/services/tournament/default.service"

export default {
	props: {
		show: Boolean,
		info: Object,
		all: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: null,
		rows: [],
		fronts: [],
		activeAccordeon: null,
	}),
	computed: {},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.rows = []
			this.$emit("closing")
		},
		async getRows() {
			this.isLoading = true
			const { data } = await tournamentService.getEventAwards({ event_id: this.info.id, all: this.all })
			this.rows = data.map((d) => ({ ...d, _open: false, _blocked: false }))
			this.rows.forEach((row) => {
				if (row.num == null) row._blocked = true
			})
			// if (this.rows.length != 0) this.activeAccordeon = 0
			this.activeAccordeon = this.rows.findIndex((row) => !row._blocked)
			console.log("ASD", this.rows, this.activeAccordeon)
			this.rows[this.activeAccordeon >= 0 ? this.activeAccordeon : 0]._open = true
			this.isLoading = false
		},
		changeAccordion(idx) {
			this.activeAccordeon = idx
			this.rows.forEach((row, i) => {
				if (idx == i) {
					row._open = !row._open
				} else {
					row._open = false
				}
			})
		},
		colorizePosition(position) {
			switch (position) {
				case 1:
					return "#FFD700"
				case 2:
					return "#C0C0C0"
				case 3:
					return "#CD7F32"
				default:
					return "gray"
			}
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.title = `Premios / ${this.info.tournament} / ${this.info.coliseum} /${moment(
					this.info.date
				).format("MM-DD-YYYY")}`
				this.getRows()
			}
		},
	},
}
</script>

<style scoped lang="scss">
.custom-select:disabled {
	color: inherit;
}
.card-border {
	border-radius: 8px !important;
}
</style>
